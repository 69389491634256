import React, { useRef, useState } from "react";
import "./App.scss";
import * as htmlToImage from "html-to-image";
import download from "downloadjs";

const App = () => {
  const [qrColor, setQrColor] = useState("#000000");
  const [qrBgColor, setQrBgColor] = useState("#ffffff");
  const [title, setTitle] = useState("");
  const [titleColor, setTitleColor] = useState("#000000");
  const [titleFont, setTitleFont] = useState("1.2rem");
  const [image, setImage] = useState("");
  const [logo, setLogo] = useState("");

  const dataInput = useRef(null);

  const generateQrCode = () => {
    if (!dataInput.current.value) return;
    setImage(
      `https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${
        dataInput.current.value
      }&color=${qrColor.substring(1)}`
    );
  };

  const  convert = (string) => {
    return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  const downloadSVG = () => {
    let _title = title ? convert(title) : "qr_code"
    htmlToImage
      .toSvg(document.getElementById("qr-code-show"))
      .then(function (dataUrl) {
        download(dataUrl, _title + ".svg");
      });
  };

  const downloadPNG = () => {
    let _title = title ? title : "qr_code"
    htmlToImage
      .toPng(document.getElementById("qr-code-show"))
      .then(function (dataUrl) {
        download(dataUrl, _title + ".png");
      });
  };

  const setTitleText = (e) => {
    setTitle(e.target.value)
    let count = e.target.value.length
    setTitleFont(1.2 -  (count/80) +"rem")
  }

  return (
    <div className="container">
      <div className="Row">
        <h1 className="text-center py-2 text-uppercase">
          Générateur de QR-CODE
        </h1>
      </div>
      <div className="row mt-5">
        <div className="col-md-6">
          <div className="p-3 border">
            <h4>Création du QR CODE</h4>
            <div className="form-group mb-3">
              <label>Texte du QR code</label>
              <textarea
                cols="30"
                ref={dataInput}
                rows="5"
                className="form-control"
              ></textarea>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="form-group mb-3">
                  <label>Choisir la couleur</label>
                  <input
                    type="color"
                    value={qrColor}
                    className="form-control"
                    onChange={(e) => setQrColor(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group mb-3">
                  <label>Choisir la couleur du fond</label>
                  <input
                    type="color"
                    onChange={(e) => setQrBgColor(e.target.value)}
                    value={qrBgColor}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="form-group mb-3">
                <label>Titre</label>
                <input
                  type="text"
                  value={title}
                  maxLength={25}
                  onChange={setTitleText}
                  className="form-control"
                />
              </div>
              <div className="form-group mb-3">
                  <label>Couleur du titre</label>
                  <input
                    type="color"
                    onChange={(e) => setTitleColor(e.target.value)}
                    value={titleColor}
                    className="form-control"
                  />
                </div>
            </div>
           {/* <div className="form-group mb-3">
              <label>Ajouter un logo</label>
              <input
                onChange={(e) =>
                  setLogo(URL.createObjectURL(e.target.files[0]))
                }
                type="file"
                className="form-control"
              />
              </div>*/}
            <div className="">
              {!image ? (
                <button
                  className="btn btn-sm btn-success"
                  onClick={generateQrCode}
                >
                  Générer le QR CODE
                </button>
              ) : (
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() => window.location.reload()}
                >
                  Nouveau QR CODE
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-6 border mt-3 p-3">
          <h5>Mon Qr code</h5>
          {!image ? null : (
            <div className="">
              <div
                id="qr-code-show"
                className="qr-code-show d-inline-block p-1"
                style={{ backgroundColor: qrBgColor }}
              >
              {!title ? null : (
                <h5
                className="qr-code-title text-center"
                style={{ color: titleColor, fontSize: titleFont }}
                >
                {title}
                </h5>
                )}
                <div className="qr-code-show-image">
                  <img src={image} alt="" />
                  {/* {logo ? <img className="logo" src={logo} alt="" /> : null} */}
                </div>
              </div>
              <div className="mt-4">
                <button className="btn btn-sm btn-success" onClick={downloadPNG}>Télécharger PNG</button>
                <button className="btn btn-sm btn-info mx-3" onClick={downloadSVG}>Télécharger SVG</button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="footer text-end mt-5 mb-3">
        <a className="text-muted small" href="https://la-ruelle-du-web.fr" _target="blank">La ruelle du WEb - Abdelhak - 2023</a>
      </div>
    </div>
  );
};

export default App;
